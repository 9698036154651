








































































































import {Vue, Component} from 'vue-property-decorator';
import { checkNickname, checkPassword, regionPhoneList, checkAccount, checkPhone } from '../utils'
import Copyright from '../components/copyright.vue'
import { getVerification, register } from '@/api/user_api'
import { throttle } from '@/utils/delayed';

@Component({
  components: {
    Copyright
  }
})
export default class Register extends Vue{
  regionPhoneList = regionPhoneList;
  getCoding = false;
  registerLoading = false;
  timeInter = 0;
  form: {[key:string]: any} = {
    fkPlatformType: {
      value: 'get_ib'
    },
    nickname: {
      value: '',
      showError: false,
      tip:'',
    },
    loginId: {
      value: '',
      showError: false,
      tip: '',
      checked: 0,         // 0 不显示 -1 错误 1正确
    },
    phoneRegion: {
      value: '',
    },
    phone: {
      value: '',
      showError: false,
      tip:'',
      checked: 0,
    },
    code: {
      value: '',
      showError: false,
      tip:'',
    },
    password: {
      value: '',
      showError: false,
      tip: '',

    },
    confirmPassword: {
      value: '',
      tip: '',
      showError: false,
    },
  }
  time = 0;
  interval:number = 0;
  // 获取验证码
  async getCode(){
    if ( this.time > 0 ) return;
    if (!(await this.validatePhone())) {
      return;
    }
    try {
      this.getCoding = true;
      const ret = await getVerification({
        mobileAreaCode: this.form.phoneRegion.value,
        mobile: this.form.phone.value, 
        fkPlatformType: this.form.fkPlatformType.value,
        type: 'register',
      })
      if(ret.data.code == 1003) {
        this.setCountDown()
      }
    } catch(e){
    }
    this.getCoding = false;
  }
  setCountDown(){
    this.time = 60;
    this.interval = setInterval(()=>{
      this.time --;
      if(this.time == 0){
        clearInterval(this.interval)
        return;
      }
    }, 1000)
  }
  resetValidate(key: string){
    this.form[key].showError = false;
    this.form[key].tip = '';
  }
  resetAllValidate(){
    for(let key in this.form){
      this.form[key].showError = false;
      this.form[key].tip = '';
    }
  }
  // 检查密码
  handleCheckPassWord(){
    if(!checkPassword(this.form.password.value)){
      this.form.password.showError = true;
      this.form.password.tip = this.$t('register_login_tip')+'';
      return true;
    }
    return false;
  }
  // 确认密码
  handleCheckConfirmPassword(){
    if(this.form.confirmPassword.value != this.form.password.value){
      this.form.confirmPassword.showError = true;
      this.form.confirmPassword.tip = this.$t('two_password_not_equal');
      return true;
    }
    return false;
  }
  // 注册
  async register(){
    this.resetAllValidate();
    let isPass = true;
    if(!this.form.nickname.value){
      this.form.nickname.showError = true;
      isPass = false;
      this.form.nickname.tip = this.$t('pleaseInputNickname')+'';
    }

    if(!checkNickname(this.form.nickname.value)){
      this.form.nickname.showError = true;
      if(!this.form.nickname.tip) this.form.nickname.tip = this.$t('accountValidateTip')+'';
      isPass = false;
    }
    if (!(await this.checkAccount())){
      isPass = false;
    }
    if(!(await this.validatePhone())){
      isPass = false;
    }
    if(!this.form.code.value){
      this.form.code.showError = true;
      this.form.code.tip = this.$t('pleaseInputCode')+'';
      isPass = false;
    }
    if(!this.form.password.value){
      this.form.password.showError = true;
      this.form.password.tip = this.$t('pleaseInputPwd')+'';
      isPass = false;
    }
    if(this.handleCheckPassWord()){
      isPass = false;
    }
    if(this.handleCheckConfirmPassword()){
      isPass = false;
    }
    if( !isPass ) return;
    try{
      this.registerLoading = true;
      const ret = await register({
        mobileAreaCode: this.form.phoneRegion.value,
        mobile: this.form.phone.value,
        fkPlatformType: 'get_ib',
        isActive: true,
        loginPs: this.form.password.value,
        verification: this.form.code.value,
        nickname: this.form.nickname.value,
        loginId: this.form.loginId.value,
      });
      if(ret.data.code == 1003){
        this.$message.success(this.$t('sthSuccess',{sth:this.$t('register')}) as string);
        this.$router.push('/login');
      }
    } catch (e){
    }
    this.registerLoading = false;
  }
  disactivated(){
    if(this.timeInter) clearTimeout(this.timeInter);
  }
  checkLoginId = throttle(this.checkAccount, 300)
  // 检查账号
  async checkAccount(){
    this.form.loginId.showError = false;
    this.form.loginId.tip = '';
    const {checked, validate, tip} = await checkAccount(this.form.loginId.value);
    if(validate) {
      this.form.loginId.checked = 1;
      return validate
    }
    this.form.loginId.checked = checked ? validate ? 1: -1: 0;
    this.form.loginId.showError = true;
    this.form.loginId.tip = tip;
    return validate;
  }
  checkMobile = throttle(this.validatePhone, 300)
  // 检查手机号
  async validatePhone(){
    this.form.phone.showError = false;
    this.form.phone.tip = '';
    const {checked, validate, tip} = await checkPhone(this.form.phone.value, this.form.phoneRegion.value)
    if(validate) {
      this.form.phone.checked = 1;
      return validate
    }
    this.form.phone.checked = checked ? validate ? 1: -1: 0;
    this.form.phone.showError = true;
    this.form.phone.tip = tip;
    return validate;
  }
}
